import classNames from 'classnames'
import React from 'react'
import { iDate } from '../../../ultils/ultis'

export default function Normal({ message, timestamp, showTime = true, isShowIcon = true, isShowLike = false, like = "none" }) {
	const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
	const words = message.split(" ");
	return (
		<div className="flex flex-col ml-2">
			{
				message
				&&
				// <div className={classNames("flex order-0", { "order-2": meta?.image_first })}>
				<div className={classNames("flex order-0")}>

					<div className="bot-messenger-content mr-2">
						{words.map((word) => {
							return word.match(URL_REGEX) ? (
								<a style={{ display: "inline" }} href={word} target="_blank" className="link-url" rel="noreferrer">{word + " "}
								</a>
							) : (
								word + " "
							);
						})}
					</div>
				</div>
			}

			{showTime && <p className="time-messenger order-3">{iDate(timestamp, " {h}:{m}")}</p>}
		</div>
	)
}
