import React from 'react'
import { iDate } from '../../../ultils/ultis'
import './style.css'

export default function SelfChat({ message, timestamp }) {
	const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
	const words = message.split(" ");

	const replaceWithBr = (message) => {
		return message.replace(/\n/g, "<br />")
	}
	return (

		<div className="self-chat flex flex-col w-full items-end">
			<div className="self-chat-content" style={{ display: "inline" }}>
				<p className="text-[#fff] text-[16px] " style={{ display: "inline" }}>
					{words.map((word) => {
						return word.match(URL_REGEX) ? (
							<a style={{ display: "inline" }} href={word} target="_blank" className="link-url" rel="noreferrer">{word + " "}
							</a>
						) : (
								word + " "
						);
					})}
				</p>

				{/* <p className="text-[#fff] text-[16px]" dangerouslySetInnerHTML={{__html: replaceWithBr(message)}} /> */}
			</div>
			<p className="time-messenger">{iDate(timestamp, " {h}:{m}")}</p>
		</div>
	)
}
