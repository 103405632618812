import React from 'react'
// import IconSmall from '../../../assets/icons/medium-icon.png'
import TYPE_BOT_MESSAGE from '../../../constants/typeMessenger'
import Normal from './Normal'
// import MessengerImage from './MessengerImage'
// import QuestionSuggest from './QuestionSuggest'
// import MessengerMarkdown from './MessengerMarkdown'
import './style.css'
import Typing from './Typing'
import timiAvartar from "../../../assets/icons/timi_avatar.png"

export default function Index({ isShowIcon = true, name, type, ...rest }) {

    const getMessenger = (type) => {
        switch (type) {

            case TYPE_BOT_MESSAGE.NORMAL:
                return <Normal {...rest} />;
            // case TYPE_BOT_MESSAGE.IMAGE:
            //     return <MessengerImage {...rest} />;
            // case TYPE_BOT_MESSAGE.IMAGES:
            //     return <MessengerImage {...rest} />;

            // case TYPE_BOT_MESSAGE.MARKDOWN:
            //     return <MessengerMarkdown {...rest} />;

            // case TYPE_BOT_MESSAGE.SUGGEST:
            //     return <QuestionSuggest {...rest} />;

            case TYPE_BOT_MESSAGE.TYPING:
                return <Typing {...rest} />;

            // case TYPE_BOT_MESSAGE.AUTO:
            //     return <MessengerAuto {...rest} />;

            // case TYPE_BOT_MESSAGE.RATING:
            //     return <Rating {...rest} />;

            default:
                return <Normal {...rest} />;
        }
    }

    let typeMes = name || type;


    return (
        <>
            <div className="bot-messenger flex w-full items-start">
                <div className="flex">
                    {
                        isShowIcon &&
                        <div>
                            <img 
                                alt="icon-chat" 
                                src={timiAvartar}
                                width="32px" 
                                height="32px"                                 
                            />
                        </div>
                    }
                </div>
                <div className="flex flex-col">
                    {getMessenger(typeMes)}
                </div>

                {/* <Normal /> */}
                {/* <QuestionSuggest /> */}
                {/* <Rating /> */}

            </div>
        </>
    )
}
