export const convertToFormatServer = (mesClient) => {
    let mesSever = {}
    if (mesClient.self) {
        mesSever.message = mesClient.text;
        mesSever.conversationId = mesClient.conversationId
    }
    return mesSever
}

export const convertToFormatClient = (mesSever) => {
    if(mesSever.isVAFschool) return mesSever;
    if(mesSever.isCustomerSend) return;
    
    let mesClient = {
        directives: [
            {
                name: "reply",
                type: "view",
                payload: {
                    text: mesSever.message || "Xin lỗi Timi hiện tại không thể trả lời câu hỏi này!"
                },
                extractors: [
                    "default",
                    "FAQSelector"
                ]
            }
        ],
        frame: {},
        nlg_template: "/utter_res_greet {}"
    }
    if (mesSever) {
        mesClient.directives[0].payload.text = mesSever.message;
        mesClient.message = mesSever.message;
        // mesClient._meta={
        //     attachments: mesSever.attachments || undefined,
        //     from: mesSever.from
        // }
    }
    return mesClient
}