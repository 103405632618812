/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames'
import React, { useEffect } from 'react'
import IconSmall from '../../assets/icons/timi_avatar.png'
import STATUS_CHAT from '../../constants/statusChat'
import { useContexts } from '../../contexts/globalContext'
import { clickSessionChat, updateClickOnConversation } from '../../services/messengerService'
import './style.css'
export default function Index({ hidden }) {
    const { status, setStatus, questionAuto } = useContexts();

    const getConversationId = () => {
        const conversationId = sessionStorage.getItem("conversation_id")
        return conversationId
    }
    // const [showQuestionSuggest, setShowQuestionSuggest] = useState(true)
    const handleClick = async () => {
        const conversationId = getConversationId();
        let params = {
            id: conversationId,
            time: new Date().getTime()
        }
        try {
            const res = await updateClickOnConversation(params);
            await clickSessionChat(window.location.search);
            console.log('res', res)
        } catch(error) {
            console.log('error', error)
        }
        // if (requiredPhone) setStatus(STATUS_CHAT.CHATTING)
        // else setStatus(STATUS_CHAT.REQUIRE_PHONE)
        setStatus(STATUS_CHAT.CHATTING)
        // setShowQuestionSuggest(false)
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         handleClick();
    //     }, 6000)
    // }, [])

    // useEffect(() => {
    //     if(status === STATUS_CHAT.BEFOR_CHAT) setShowQuestionSuggest(true)
    // }, [questionAuto])
    return (
        <div 
            className={classNames('wrap-logo relative', { hidden: !(status === STATUS_CHAT.BEFOR_CHAT) })}
        >
            <span className='tooltiptext' onClick={handleClick}>Chatbot GPT</span>
            <div className="small-logo-chat cursor-pointer live-icon" onClick={handleClick}>
                <img
                    src={IconSmall}
                    alt="icon-chat"
                    width="70px"
                    height="70px"
                    className='valivechatfschool'
                />
            </div>
            {/* {
                showQuestionSuggest
                &&
                <div className="mesenger-auto absolute" onClick={handleClick}>
                    <p className="mesenger-auto-text cursor-pointer break-word">
                        {questionAuto?.question}
                    </p>
                </div>
            } */}

        </div>

    )
}
