export const iDate = (date, stringFormat) => {
    try {
      let t;
  
      if (/(^\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2}$)/.test(date)) {
        date = date?.split(/\D+/) || [];
        date[1] = Number(date[1]) - 1;
        t = new Date(...date);
      } else {
        t = new Date(date || new Date());
      }
  
      if (!t.getDate()) {
        return "";
      }
  
      const s = Number(t);
      const h = t.getHours();
      const o = {
        D: t.getDay(), // index ngày trong tuần (number)
        H: t.getHours(), // giờ (number/H)
        J: t.getDate(), //ngày trong tháng (number)
        M: t.getMinutes(), //phút (number/M)
        N: t.getMonth() + 1, //tháng Việt Nam (number)
        O: t, //(Date)
        S: t.getSeconds(), // giây (number)
        d: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"][t.getDay()], //thứ Việt nam (String)
        f: t.getFullYear(), //năm (number)
        h: ("0" + t.getHours()).substr(-2), //giờ (String)
        j: ("0" + t.getDate()).substr(-2), // ngày (String)
        m: ("0" + t.getMinutes()).substr(-2), //phút (String)
        n: ("0" + (t.getMonth() + 1)).substr(-2), // tháng (String)
        s: ("0" + t.getSeconds()).substr(-2), //giây (String)
        t: s, //mls (number)
        u: parseInt(s / 1000), //timestamp (number)
        y: String(t.getFullYear()).substr(-2), //2 số cuối của năm (String)
        A: t.getHours() >= 12 ? "PM" : "AM", // AM PM                 t>new Date (t.getFullYear,t.getMonth(),t.getDate,12,"01","00")
        g: ("0" + (h === 0 ? 12 : h > 12 ? h - 12 : h)).substr(-2), // Trả về định dạng 12 giờ
        Month: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][t.getMonth()],
        Mo: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ][t.getMonth()],
      };
      if (typeof stringFormat === "string") {
        return stringFormat.replace(
          /{(Mo|Month|.)}/g,
          (a) => o[a.replace(/[{}]/g, "")]
        );
      }
  
      return o;
    } catch (error) {
      //console.log("error", error);
  
      return date;
    }
  };

  export function shuffle(arrayOrg) {
    let array = [...arrayOrg]
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

export const isMobile = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const getParameterByName = (name, url) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  
  