import React from 'react'
import TypingDot from '../../TypingDot'

export default function Typing() {
    return (
        <div className="flex flex-col" >
            <TypingDot />
        </div>

    )
}
