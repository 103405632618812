import React from 'react'
import './style.css'
export default function TypingDot() {
    return (
        <div className="ticontainer ml-2">
            <div className="tiblock">
                <div className="tidot"></div>
                <div className="tidot"></div>
                <div className="tidot"></div>
            </div>
        </div>
    )
}
