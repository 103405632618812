/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    createContext, useCallback, useContext, useEffect, useMemo, useRef, useState
} from "react";
import { ToastContainer } from "react-toastify";
import { io } from 'socket.io-client';
import TYPE_BOT_MESSAGE from "../constants/typeMessenger";
import useTogglePopup from "../hooks/useTogglePopup";
import { getAllMessagesFromServer } from "../services/messengerService";
import { convertToFormatServer } from "../ultils/convertFormatMes";
import { getUserId } from "../ultils/genUuid";
import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';
import { getParameterByName, retry } from "../ultils/ultis";
let socket;
export const MessengerContext = createContext();
export const useContexts = () => useContext(MessengerContext);

ReactPixel.init('550702023496103');
// ReactGA.initialize('G-G010G42EDZ');

export const Provider = ({ children, idProject }) => {
    const [rated, setRated] = useState(false)
    const [listMessenger, setListMessenger] = useState([]);
    const [newMessage, setNewMessage] = useState({});
    // const { show: showPopup, handleClose: handleClosePopup, togglePopup: togglePopupPopup } = useTogglePopup();
    // const { show: showTyping, handleClose, togglePopup: toggleTyping, handleShow } = useTogglePopup();
    const { show, handleClose, handleShow, togglePopup} = useTogglePopup();
    const [uiPopup, setUiPopup] = useState(null)
    const turnFinished = useRef(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const getCustomerName = () => {
        const username = localStorage.getItem("username")
        if (!username) {
            setListMessenger([])
        }
        return username
    }

    const getConversationId = () => {
        const conversationId = sessionStorage.getItem("conversation_id")
        if (!conversationId) {
            setListMessenger([])
        }
        return conversationId
    }

    const getUrlFromFQA = () => {
        const url = window.location.href;
        return url;
    }

    const getQueryStringFromFQA = (query) => {
        const urlFromFQA = getUrlFromFQA();
        const queryValue = getParameterByName(query, urlFromFQA);
        return queryValue;
    }

    // const listQuestionRandom = [listAutoQuestionSuggest[0], ...shuffle(listAutoQuestionSuggest.slice(1))]
    // const [questionAuto, setQuestionAuto] = useState(listQuestionRandom[0])  // câu hỏi chủ động bên trong độc lập với bên ngoài bong bóng
    // const timeOutQuestionAuto = useRef(null)
    // const turnChangeQuestion = useRef(1)

    useEffect(() => {
        if (!newMessage?.from) return;
        setListMessenger(x => x.concat(newMessage));
    }, [newMessage])

    const getAllMessages = async (conversationId, params, init = false) => {
        try {
            setLoading(true);
            let data = await getAllMessagesFromServer(conversationId, params);
            setPage(data?.paginate?.page);
            setHasMore(data?.has_more);
            const newList = data?.messages?.sort((a, b) => {
                return a?.timestamp - b?.timestamp
            });
            console.log('newList', newList, init)
            if (init)
                setListMessenger(newList)
            else
                setListMessenger([...newList, ...listMessenger]);
        }
        catch (error) {
            console.log('error', error);
        }
        finally {
            setLoading(false);
            const contentChat = document.querySelector('.content-chat')
            if (!init) {
                contentChat?.childNodes?.[limit - 1]?.scrollIntoView();
                return;
            }
            contentChat?.lastChild?.scrollIntoView({ behavior: "smooth" });
        }
    }

    useEffect(() => {
        if (page === 1) {
            return;
        }
        const conversationId = getConversationId();
        if (conversationId) {
            getAllMessages(conversationId, { page, limit });
        }
    }, [page])

    const loadMoreMessage = useCallback(() => {
        if (hasMore) {
            setPage(page => page + 1);
        }
    }, [hasMore])

    // tạm tắt tin nhắn chủ động
    // useEffect(() => {
    //     addMessenger({
    //         type: TYPE_BOT_MESSAGE.AUTO,
    //         text: questionAuto?.question,
    //         answer: questionAuto?.answer,
    //         _meta: { 
    //             buttons: [
    //                 {
    //                     title: "oke",
    //                     payload: "oke"
    //                 }
    //             ]
    //         }
    //     })
    // }, [questionAuto])

    useEffect(() => {
        //kết nối socket
        const connectSocketFunc = () => {
            let isFirstNotification = true;
            const customerName = getCustomerName();
            const timestamp = new Date().getTime();
            const sessionId = `${customerName}_${timestamp}`;
            setPage(1)
            setListMessenger([])

            if (!customerName) {
                return console.log("No username found in localstorage, return.");
            }

            if (socket && socket.connected) {
                console.log("Socket connected, re init.");
                socket.removeAllListeners();
                socket.disconnect();
                socket.close();
            }

            socket = io(process.env.REACT_APP_END_POINT_CHAT_BOT, {
                transports: ["websocket"],
                path: "/socketLiveChat",
                query: {
                    projectId: idProject || "632ae114d91c183f0299bdfe",
                    customerName,
                    userId: getUserId(),
                    clientUrl: process.env.REACT_APP_DOMAIN_CLIENT,
                    sessionId,
                    utm_source: getQueryStringFromFQA('utm_source'),
                    utm_medium: getQueryStringFromFQA('utm_medium'),
                    utm_campaign: getQueryStringFromFQA('utm_campaign')
                },
            });

            socket.on("connect_error", async (err) => {
                // toast.error("Connect socket error: " + err.message);
            });

            socket.on("notification", async ({ type, message, conversation }) => {
                // console.log('message', message)
                sessionStorage.setItem("user_id", conversation?.customer);
                if (conversation?._id) {
                    sessionStorage.setItem("conversation_id", conversation?._id);
                }
                // try {
                //     toast[type](message);
                // } catch (error) {
                //     toast.error(error.message);
                // }

                if (conversation?._id && isFirstNotification) {
                    const conversationId = conversation?._id
                    const params = { page: 1, limit: 10 }
                    const init = true;
                    getAllMessages(conversationId, params, init)
                    isFirstNotification = false;
                }
            });


            //lắng nghe tin nhắn từ server
            socket.on("send_message", async (message) => {
                try {
                    handleShow();
                    // await (() => new Promise((reslove) => setTimeout(reslove, 4000)))()

                    if (message?.from === getUserId()) {
                        window.initVA.lastServerMessageFromUser = message;
                    }
                    
                    if(!message?.isCustomerSend) {
                        handleClose();
                    }
                    /* 
                        Check xem message đã có trong ds chưa rồi mới setNewMessage
                        todo: cần sửa lại phía server, cần lưu thêm 1 biến ramdom_id từ phía client 
                        để xác định tin nhắn đã có trong listMessenger hay chưa
                    */
                    setListMessenger((lstMsgs) => {
                        const isExist = lstMsgs.slice(-10).find(msg => msg.fromTimeOut && msg.message === message.message)
                        if (!isExist) {
                            setNewMessage(message);
                        }
                        return lstMsgs
                    })
                    
                } catch (error) {
                    // toast.error(error.message);
                }
            });

            //bắt đầu tạo conversation cho cuộc hội thoại
            let payload = { type: "LIVECHAT" };
            socket.emit("create_conversation", payload);
        }
        window.initVA = connectSocketFunc;
        window.initVA.socket = socket;
        window.initVA()
        return () => socket && socket.disconnect()
    }, [])

    // useEffect(() => {

    //     //bắt đầu tạo conversation cho cuộc hội thoại
    //     let payload = { type: "LIVECHAT" };
    //     socket.emit("create_conversation", payload);
    // }, [])

    const addMessenger = (messenger) => {
        let newMes = { ...messenger, time: new Date() };
        setListMessenger(list => {
            let listClone = list
            // if (listClone.length > 0 && listClone[listClone?.length - 1]?.payload?.buttons?.length > 0) {
            //     listClone[listClone?.length - 1].payload.buttons = []   // xoá suggest sau khi có thao tác tiếp
            // }
            return [...listClone, newMes]
        })

    }

    // const addMessengerBoot = (messenger) => {
    //     const listNewMes = messenger?.directives?.map(mes => ({ ...mes, time: new Date() })) || [{}]
    //     setListMessenger(list => {
    //         let listClone = list
    //         // if (listClone.length > 0 && listClone[listClone?.length - 1]?.payload?.buttons?.length > 0) {
    //         //     listClone[listClone?.length - 1].payload.buttons = []   // xoá suggest sau khi có thao tác tiếp
    //         // }
    //         return [...listClone, ...listNewMes]
    //     })

    // }

    // const getAnswer = useCallback(
    //     async (messenger) => {
    //         // if (messenger.text === TEXT_REQUIRE_RATING) {
    //         //     requireRating();
    //         //     return
    //         // }
    //         let newMes = { context: {}, ...messenger, sender_id: getUserId(), time: new Date() };

    //         if (newMes.payload) {
    //             newMes.text = newMes.payload
    //             delete newMes.payload;
    //         }
    //         if (newMes.self) delete newMes.self;

    //         toggleTyping() // hiện đang gõ
    //         let startTime = performance.now()
    //         let answer = await getAnswerBot({ data: newMes });
    //         let endTime = performance.now()
    //         const timeDelay = (answer?.frame?.delay || 0) * 1000;
    //         // let remainTime = TIME_DELAY_ANSER - (endTime - startTime)
    //         let remainTime = timeDelay - (endTime - startTime)

    //         // let answer = {
    //         //     text: "oke bạn",
    //         //     meta:{}
    //         // };

    //         if (answer) {

    //             if (remainTime > 0) {
    //                 setTimeout(() => {
    //                     closeTyping();
    //                     addMessengerBoot(answer);

    //                     // if (answer?.text === `/request_rating_conversation\n`) {
    //                     //     // check điều kiện là triger đánh giá từ bot
    //                     //     requireRating()
    //                     // }
    //                     // else {
    //                     //     addMessenger(answer);
    //                     // }

    //                     // check điều kiện đánh giá
    //                     // turnFinished.current = turnFinished.current + 1
    //                     // // console.log(`turnFinished.current`, turnFinished.current)
    //                     // if (turnFinished.current === TURN_FINISHED_RATING && !rated) {
    //                     //     requireRating()
    //                     // }
    //                 }, remainTime)
    //             }
    //             else {
    //                 addMessengerBoot(answer);
    //                 closeTyping()
    //             }
    //         }
    //     },
    //     [userId, rated, turnFinished]
    // )

    // const requireRating = () => {
    //     let newMes = {
    //         time: new Date(),
    //         type: TYPE_BOT_MESSAGE.RATING,
    //         text: "Bạn đánh giá tính năng trợ lý ảo của Ftech.ai thế nào ?",
    //         refresh: true
    //     }
    //     addMessenger(newMes)
    // }

    const onFocusInput = () => {
        //Customer seen message
        if (!getConversationId()) return;
        socket.emit("customer_seen_message", getConversationId());
    }

    const sendMessage = (message) => {
        // setNewMessage({
        //     from: getUserId(),
        //     message,
        //     timestamp: new Date().getTime()
        // })
        socket.emit('send_message', {
            conversationId: getConversationId(),
            message,
            customerId: getUserId(),
            customerName: getCustomerName()
        });

        if (process.env.REACT_APP_SYSTEM_ENV === "PROD") {
            ReactPixel.trackCustom('Sendmessage')
            // ReactGA.event({
            //     category: "Chatbot",
            //     action: "Sendmessage"
            // });
            if (window.ttq) {
                window.ttq.track('ClickButton')
            } else {
                window.ttq_va && window.ttq_va.track('ClickButton')
            }
            if (window.gtag) {
                window.gtag("event", "Sendmessage");
            } else {
                window.gtag_va && window.gtag_va("event", "Sendmessage");
            }
        }

        // Tại sao có đoạn code này?
        /*
        Khi gửi tin nhắn mới, server emit "send_message" cho client
        - Trên Safari bị lỗi, ko listen được sự kiện này,
        nên phải setTimeOut check xem đã nhận được message từ server emit "send_message" chưa
        + nhận rồi: thì socket.on("send_message" ... đã setNewMessage rồi nên ko set nữa
        + chưa nhận: thì chủ động setNewMessage ở đây, delay 1s
        */
        clearTimeout(window.initVA.lastServerMessageFromUserTimeOut);
        window.initVA.lastServerMessageFromUserTimeOut = setTimeout(() => {
            if (window.initVA?.lastServerMessageFromUser?.message === message) {
                return;
            }
            const newMessageData = {
                fromTimeOut: true,
                from: getUserId(),
                message,
                timestamp: new Date().getTime()
            }
            setNewMessage(newMessageData)
            window.initVA.lastServerMessageFromUser = null
        }, 1000)
    }

    const refreshData = () => {
        if (!sessionStorage.getItem("conversation_id"))
            setListMessenger([])
        else
            setPage(1)
        // getAllMessages(conversationId, { page: 1, limit }, true)
    }

    useEffect(() => {
        if (listMessenger.length === 0) {
            return;
        }

        let lastMes = listMessenger[listMessenger.length - 1];
        lastMes.conversationId = getConversationId()

        if (lastMes.type === TYPE_BOT_MESSAGE.AGREE_SUGGEST) return;
        if (lastMes.self) {
            // getAnswer(lastMes)
            socket.emit("send_message", convertToFormatServer(lastMes));

        }

        // if (timeOutQuestionAuto.current) clearTimeout(timeOutQuestionAuto.current)
        // if (lastMes?.type !== TYPE_BOT_MESSAGE.AUTO)
        //     timeOutQuestionAuto.current = setTimeout(() => {
        //         let index = turnChangeQuestion.current % (listQuestionRandom.length)
        //         let randomQuestion = listQuestionRandom[index];
        //         setQuestionAuto(randomQuestion)
        //         turnChangeQuestion.current = turnChangeQuestion.current + 1;

        //     }, TIME_MESSAGE_AUTO)

    }, [listMessenger.length])

    const value = useMemo(
        () => ({
            rated, setRated, onFocusInput, loadMoreMessage,
            hasMore, listMessenger, addMessenger, sendMessage,
            handleClose, togglePopup,
            uiPopup, setUiPopup, show, newMessage,
            refreshData, handleShow
        }),
        [rated, listMessenger, uiPopup, show]
    );

    return (
        <MessengerContext.Provider value={value}>
            <ToastContainer />
            {children}
        </MessengerContext.Provider>
    );
};
