const TYPE_BOT_MESSAGE = {
    NORMAL: "reply",
    RATING: "RATING",
    IMAGE: "image",
    IMAGES: "images",
    MARKDOWN: "markdown",
    SUGGEST : "buttons",
    AUTO: "AUTO",
    AGREE_SUGGEST : "AGREE_SUGGEST",
    TYPING : "TYPING",

}

export default TYPE_BOT_MESSAGE