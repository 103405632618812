import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Menu, Send, X } from 'react-feather'
import IconLogo from '../../assets/icons/medium-icon.png'
import MenuSuggest from '../../components/MenuSuggest'
import BotMessenger from '../../components/Messenger/BotMessenger/index'
import SelfChat from '../../components/Messenger/SelfMesseger'
import STATUS_CHAT from '../../constants/statusChat'
import TYPE_BOT_MESSAGE from '../../constants/typeMessenger'
import { useContexts as useGolbalContexts } from '../../contexts/globalContext'
import { Provider, useContexts } from '../../contexts/messengerContext'
import { useTogglePopup } from '../../hooks'
import './style.css'
import timiAvatar from '../../assets/icons/timi_avatar.png'
import { getUserId } from '../../ultils/genUuid'
import { toast } from 'react-toastify'

function WindowsChatImpl() {
    const { status, setStatus } = useGolbalContexts();
    // const { show, togglePopup, handleClose } = useTogglePopup();
    const { listMessenger, loadMoreMessage, showPopup,
        uiPopup, show, onFocusInput, sendMessage,
        hasMore, newMessage, refreshData,
    } = useContexts();
    const [messengerInput, setMessengerInput] = useState("");
    const listChatRef = useRef(null);

	const handleScroll = () => {
		const contentChat = document.querySelector('.content-chat')
		if(!hasMore) return;
		window.addEventListener('scroll', contentChat?.lastChild?.scrollIntoView({ behavior: "smooth" }));
		return () => {
			window.removeEventListener('scroll', contentChat?.lastChild?.scrollIntoView({ behavior: "smooth" }));
		};
	}

	useEffect(() => {
		handleScroll()
	}, [newMessage]);

    const loadMore = () => {
        if (listChatRef.current) {
            const { scrollTop } = listChatRef.current;
            if (scrollTop === 0 && hasMore) {
                loadMoreMessage();
            }
        }
    };

    const handleSendMes = () => {
        if (messengerInput === "") return;
        // addMessenger({
        //     self: true,
        //     text: messengerInput
        // })
        sendMessage(messengerInput);
        setMessengerInput("")
    }

    const handlenput = useCallback(
        (e) => {
            let value = e.target.value;
            setMessengerInput(value)
        },
        [],
    )

    const handleKeyDow = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {

            if (!localStorage.getItem("username")) {
                e.preventDefault();
                toast.error("Vui lòng đăng nhập hoặc tải lại trang để dùng tính năng này.")
                return;
            }

            handleSendMes();
            e.target.style.cssText = 'height:' + 24 + 'px';
            e.preventDefault();
            return
        }

    }

    const onEmojiClick = (emojiObject) => {
        setMessengerInput(messengerInput + emojiObject.native);
    };

    const autosize = (e) => {
        var el = e.target;
        setTimeout(function () {
            el.style.cssText = 'height:auto; padding:0';
            // for box-sizing other than "content-box" use:
            //   el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + el.scrollHeight + 'px';
        }, 0);
    }

	useEffect(() => {
		if (status === STATUS_CHAT.CHATTING) {
			refreshData && refreshData()
			handleScroll()
		}
	}, [status])

    return (
        <div
            className={classNames('windows-chat flex flex-col', { hidden: !(status === STATUS_CHAT.CHATTING) })}
        >
            {showPopup && uiPopup}
            <div className="top-bar-chat flex flex-row justify-between items-center">
                <div className="ml-2 mr-3">
                    <img
                        alt="icon-medium"
                        width="40px"
                        height="auto"
                        src={timiAvatar}
                    />
                </div>
                <div className="flex-grow my-1 font-F">
                    <p className="m-0 font-semibold text-[16px] text-[#2E2E2E]">Timi_Trợ lý học tập</p>
                    <p className="m-0 ChatGPT"><a href='https://fqa.vn/dieu-khoan-dich-vu.html' target="_blank" >FQA không chịu trách nhiệm nội dung từ ChatGPT</a></p>
                </div>
                <div className="icon-close-chat mr-4 " id="iconCloseChatGPT" onClick={() => { setStatus(STATUS_CHAT.BEFOR_CHAT) }} >
                    <X size={20} color="#595959" fill="#F6F6F6" layout="none" />
                </div>
            </div>

            <div className="content-chat flex flex-col flex-grow px-2 py-3 relative" ref={listChatRef} onScroll={() => loadMore()}>
                {hasMore && <div className="lds-dual-ring"></div>}
                {
                    listMessenger.map((item, index) => {
                        return (
                            <div className="flex" key={index}>
                                {/* {item?.from === userId ? < SelfChat {...item} /> : <BotMessenger {...item} />} */}
                                {item?.from === getUserId() ? < SelfChat {...item} /> : <BotMessenger {...item} />}
                            </div>
                        )
                    })
                }
                {show && <BotMessenger type={TYPE_BOT_MESSAGE.TYPING} />}

            </div>

            <div className="footer-chat flex flex-row items-center ">
                <div className="input-chat flex-grow mx-4 my-4 flex items-center">
                    {/* <input type="text" className="w-full" value={messengerInput} onChange={handlenput} onKeyDown={handleKeyDow} placeholder="Nhập tin nhắn tại đây..." /> */}
                    <textarea
                        className="w-full"
                        rows={1}
                        onInput={autosize}
                        value={messengerInput}
                        onChange={handlenput}
                        onKeyDown={handleKeyDow}
                        onFocus={onFocusInput}
                        placeholder="Bạn hỏi gì đi..." />
                </div>

                <div className={classNames("icon-send-messenger pr-4", { 'cursor-pointer': messengerInput?.length > 0 })}>
                    <Send className='sendmessage' onClick={handleSendMes} size={20} color={messengerInput?.length === 0 ? "#9F9F9F" : "#FF884B"} />
                </div>
            </div>
        </div>
    )
}



const WindowsChat = (props) => (
    <Provider idProject={props?.idProject} customerName={props.customerName}>
        <WindowsChatImpl {...props} />
    </Provider >
)

export default memo(WindowsChat)
