import getDomainBotChat, { getFQADomain, getVaCrmDomain } from "../ultils/api";
import { postAsync, getAsync } from "../ultils/request";

export async function getAnswerBot(options) {
    let user_id = options?.user_id || options?.data?.meta?.user_id
    let data = options?.data
    let url = getDomainBotChat() + "/api/parse";
    const response = await postAsync(url, data);
    let result = response?.data
    // if (result?.status !== 0) toastError(result?.message || result?.msg || "Xảy ra lỗi khi tải danh sách game")
    return result || {};
}

export async function getAllMessagesFromServer(conversationId, params) {
    const paramsString = new URLSearchParams(params).toString();
    let url = getVaCrmDomain() + `/v1/api/message/get_message_by_conversation?conversationId=${conversationId}&${paramsString}`;
    const response = await getAsync(url);
    return response?.data?.data;
}

export async function updateClickOnConversation(params) {
    let url = getVaCrmDomain() + '/v1/api/conversations/update_click_on_conversation';
    const response = await postAsync(url, params);
    return response?.data?.data;
}

export async function clickSessionChat(params) {
    const paramsString = new URLSearchParams(params).toString();
    let url = getFQADomain() + `/api/v1/external/social/chatbot/tracking?${paramsString}`;
    const response = await postAsync(url);
    return response?.data?.data;
}