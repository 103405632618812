import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './tailwind-output.css'
import './markdown.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

const sentryDns = process.env.REACT_APP_SENTRY_DNS
if (sentryDns) {
  Sentry.init({
    dsn: sentryDns,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const domVaLiveChat = document.getElementById('va-live-chat-fschool');
const idProject = (domVaLiveChat && domVaLiveChat.getAttribute("project_id")) || "632ae114d91c183f0299bdfe";
const customerName = (domVaLiveChat && domVaLiveChat.getAttribute("username")) || "";
// console.log('customerName :>> ', customerName);
ReactDOM.render(
  <React.StrictMode>
    <App idProject={idProject} customerName={customerName}/>
  </React.StrictMode>,
  document.getElementById('va-live-chat-fschool')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
