import React from "react"
import './App.css';
// import {
//   BrowserRouter as Router,
//   Route, Switch
// } from "react-router-dom";
import IconSmall from './components/IconSmall';
import { Provider } from './contexts/globalContext';
// import RequirePhone from './screens/RequirePhone';
import WindowsChat from './screens/WindowsChat';

function AppImpl(props) {
  return (
    <>
      <div className="valivechatfschool chat-bot-ftech">
        <WindowsChat {...props} />
        {/* <RequirePhone /> */}
        <IconSmall/>
      </div>
    </>
  );
}


const App = (props) => ( 

  <Provider idProject={props?.idProject} customerName={props.customerName}>
     <AppImpl {...props} />
  </Provider >
)
                                                                      
export default App

