export default function getDomainBotChat(){
    return `${process.env.REACT_APP_END_POINT_CHAT_BOT}`;
}

export const getVaCrmDomain = () => {
    return `${process.env.REACT_APP_DOMAIN_VA_CRM}`;
}

export const getFQADomain = () => {
    return `${process.env.REACT_APP_DOMAIN_FQA}`;
}